import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'

const Div = styled.div`
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  img {
    width: 50%;
  }
  a {
    color: blue;
  }
  a::visited {
    color: blueviolet;
  }

  @media (max-width: 600px) {
    img {
      width: 80%;
    }
  }
`

const PlaceOfMemoryTemplate = (props) => {
  const post = get(props, 'data.contentfulPlaceOfMemory')
  const link = new URL(post.link)
  const isYandex = link.host === "yandex.ru"
  const mid = link.searchParams.get('mid')

  return (
    <Layout location={props.location}>
      <Div
        style={
          {
            // background: '#f2f1e2',
            // borderRadius: 5,
            // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
          }
        }
      >
        <Helmet title={post.slug} />
        <div className="wrapper">
          <h1 className="section-headline">{post.slug}</h1>
          {isYandex
            ? <iframe
              src={post.link}
              width="640"
              height="480"
            />
            : <iframe
              src={`https://www.google.com/maps/d/embed?mid=${mid}`}
              width="640"
              height="480"
              />
          }
          <Link to={post.link} target="_blank" rel="noopener noreferrer">
            {post.link}
          </Link>
          {post.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      </Div>
    </Layout>
  )
}
export default PlaceOfMemoryTemplate

export const pageQuery = graphql`
  query PlaceOfMemoryQuery($slug: String!) {
    contentfulPlaceOfMemory(slug: { eq: $slug }) {
      slug
      type
      link
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
